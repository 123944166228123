export const AdminApi = {
  ROLES_PAGINATE: "admin/roles",
  ROLES_VIEW: "admin/roles/view/:id",
  ROLES_SAVE: "admin/roles/save/:id?",
  ROLES_DELETE: "admin/roles/delete/:id",

  PERMISSIONS_LIST: "admin/role-permission/permissions",
  MODULES_LIST: "admin/role-permission/modules",

  ROLE_PERMISSION: "admin/role-permission/view/:id",

  TIME_TRACKING: "helper/logout-time",

  ADMIN_DELETE: "admin/users/delete/:id",
  ADD_ADMIN: "admin/auth/admin-registration",
  EDIT_ADMIN: "admin-profile/save/:id?",
  ADMIN_VIEW: "admin-profile/view/:id",
  GET_ROLLS: "helper/get-roles",
  ADMIN_USER_PAGINATION: "helper/get-admin-users",

  COURSES_PAGINATE: "admin/courses",
  TEACHER_COURSES_PAGINATE: "admin/courses/teacher-courses",
  TEACHER_STUDENT_PAGINATE: "admin/courses/teacher-courses/:id",
  COURSES_VIEW: "admin/courses/view/:id",
  COURSES_SAVE: "admin/courses/save/:id?",
  COURSES_DELETE: "admin/courses/delete/:id",
  STUDENT_COURSE: "admin/courses/student-view/:slug",
  MARK_LESSON_DONE: "admin/courses/mark-lesson-done",

  QUIZ_PAGINATE: "admin/quiz",
  QUIZ_VIEW: "admin/quiz/view/:id",
  QUIZ_SAVE: "admin/quiz/save/:id?",
  QUIZ_DELETE: "admin/quiz/delete/:id",

  USERS: "admin/users",

  TEACHERS: "helper/teachers",
  QUIZ: "helper/quizes/:course_id?",
  Demo_Video: "helper/demoVideo/:id",

  FORUM_SAVE: "forums/save/:id?",
  FORUM_DELETE: "forums/delete/:id",
  FORUM_THREAD_DELETE: "forum_threads/delete/:id",
  FORUM_PAGINATE_C: "forums/type/Course",
  FORUM_PAGINATE_G: "forums/type/General",
  FORUM_VIEW: "forums/view/:id",
  FORUM_THREAD_VIEW: "forum_threads/view/:slug",
  FORUM_THREADS_VIEW: "helper/forum-threads",
  FORUM_THREADS_VIEW_UPDATE: "forum_threads/view/:slug",
  FORUM_THREAD_SAVE: "forum_threads/save/:id?",
  FORUM_COMMENTS_VIEW: "forum_comment/view/:id",
  FORUM_ARCHIVE: "forums/archiveForum/:id",
  FORUM_ARCHIVE_VIEW_GENERAL: "forums/type/General?is_archive=true",
  FORUM_ARCHIVE_VIEW_COURSE: "forums/type/Course?is_archive=true",


  COMMENT_SAVE: "forum_comment/save/:id?",

  CATEGORY_LIST: "forums_category/categories_list",
  CATEGORY_PAGINATE: "forums_category",
  CATEGORY_SAVE: "forums_category/save/:id?",
  CATEGORY_VIEW: "forums_category/view/:id?",
  CATEGORY_DELETE: "forums_category/delete/:id",

  DISCOUNT_PAGINATE: "discount-promotion-code",
  DISCOUNT_VIEW: "discount-promotion-code/view/:id",
  DISCOUNT_SAVE: "discount-promotion-code/save/:id?",
  DISCOUNT_CODE: "admin/course/checkout/apply-discount",
  DISCOUNT_DELETE: "admin/course/checkout/removeDiscount/:cartId",
  DISCOUNT_PERMOTION: "discount-promotion-code/delete/:id",
  DISCOUNT_PERMOTION_COURSES: "discount-promotion-code/courses-for-discountPromotion/:id?",

  FORUM_COMMENTS: "forums/view/33",

  ADD_TO_CART: "admin/course/cart",
  CHILD_LIST: "parent/auth/getStudents/:id",
  ADD_TO_CART_LIST: "admin/course/checkout/by-cart/:id",
  CART_ITEM_REMOVE: "admin/course/removeCartItemById/:id",
  STRIPE_PROCCES: "admin/course/checkout/process",
  CART_ITEM_INSTALLMENT:"admin/course/checkout/installmentSchedule/:id",
  FULLY_PAID_COURSES:"admin/installment-process/fully-paid-courses",
  ADMIN_ASSIGNED_COURSES:"admin/course-access-transition/admin-assigned-courses",
  INSTALLMENT_PAID_COURSES:"admin/installment-process/installment-paid-courses",
  INSTALLMENT_PAID_COURSES_LIST:"admin/installment-process/courses-by-paymentId/:id",
  INSTALLMENT_PAID_PROCCES:"admin/installment-process/process-payment",
  INSTALLMENT_SCHEDULES:"admin/installment-process/installment-schedules/:id",




  STUDENT_ASSIGN_COURSES: "admin/student/courses/:id",
  STUDENT_ASSIGN_ASSIGNMENTS: "admin/student/assignments/:id",
  STUDENT_ASSIGNMENT_VIEW: "admin/student-assignment/:id",
  STUDENT_PRIVATE_COMMENT: "admin/private-comment/save",
  STUDENT_SUBMIT_ASSIGNMENT: "admin/student-assignment/submit-assignment",
  STUDENT_RESUBMIT_ASSIGNMENT: "admin/reassign-assignment/request-reassign",
  STUDENT_RESUBMIT_ASSIGNMENT_CANCLE: "admin/reassign-assignment/cancel-reassign-request/:id",
  STUDENT_ASSIGN_QUIZZES: "admin/student/quizzes/:id",
  STUDENT_COURSES_TEACHER: "admin/student/teachers/:id",
  STUDENT_SINGLE_CARD: "helper/getStudent/:id",
  STUDENT_STATS: "helper/getStudentStat",
  STUDENT_START_QUIZ: "admin/student-quiz/start-quiz",
  STUDENT_SAVE_QUIZ: "admin/student-quiz/answer-quiz",
  STUDENT_QUIT_QUIZ: "admin/student-quiz/end-quiz",
  STUDENT_QUIT_RESULT: "admin/student-quiz/quiz-result/:id",
  REASSIGN_ASSIGNMENT_REQUEST: "admin/reassign-assignment/process-request",

  TRANSACTION_PAGINATE: "admin/transactions",
  TRANSACTION_VIEW: "admin/transactions/view/:id",
  TRANSACTION_REPORT: "admin/transactions/report",
  TRANSACTION_REPORT_PEARENT: "admin/transactions/report-by-user",

  ENROLLED_STUDENTS_PAGINATE: "admin/enrolled-students",
  ASSINED_TEACHERS: "admin/student/:id",

  SUPPORT_PAGINATE: "admin/admin-support",
  SUPPORT_VIEW: "admin/admin-support/view/:id",
  SUPPORT_POST_MESSAGE: "admin/admin-support/post-message/:id",
  SUPPORT_EDIT: "admin/admin-support/save/:id?",

  COMPLAINTS_PAGINATE: "admin/complaint",
  COMPLAINTS_BY_ID_PAGINATE: "admin/complaint/complaints-by-user",
  COMPLAINTS_VIEW: "admin/complaint/view/:id",
  COMPLAINTS_EDIT: "admin/complaint/save/:id?",
  COMPLAINTS_DEL: "admin/complaint/delete/:id",

  QUESTIONBANK_PAGINATE: "admin/question-bank",
  QUESTIONBANK_DEL: "admin/question-bank/delete/:id",
  QUESTIONBANK_EDIT: "admin/question-bank/save/:id?",
  QUESTIONBANK_VIEW: "admin/question-bank/view/:id?",

  REGISTER_FCM_TOKEN: "admin/notifications/register-token/:id",

  REFUND_REQUEST: "admin/transactions/refund/:id?",
  REFUND_REQUEST_PAGINATE: "admin/transactions/refund",
  UPDATE_REFUND_STATUS: "admin/transactions/refund/update/:id?",

  DASHBOARD_STATS: "admin/dashboard/stats",
  CHECK_AUTH: "admin/dashboard/validate-token",

  CHAT_USERS: "helper/get-chat-users/:id",

  STUDENT_ACTIVITY: "helper/get-student-activity/:id",

  SUBMIT_ASSIGNMENT_MARKS: "admin/student-assignment/assign-marks/:id?",

  TOP_STUDENTS: "admin/dashboard/top-students",

  TEACHER_FEEDBACK_PAGINATE: "admin/teacher-feedback",

  TEACHER_FEEDBACK_SAVE: "admin/teacher-feedback/save",

  USER_DEL_BY_ADMIN: "admin/users/delete/:id",
  USER_ACTIVE_ADMIN: "helper/deactivation-process/:id",
  USER_STUDENT_PAGINATE: "admin/users/students",
  USER_STUDENT_DETAIL: "admin/users/student-details/:id",

  USER_TEACHER_PAGINATE: "admin/users/teachers",
  USER_TEACHER_DETAIL: "admin/users/teacher-details/:id",

  USER_PARENT_PAGINATE: "admin/users/parents",
  USER_PARENT_DETAIL: "admin/users/parent-details/:id",

  STUDENT_COURSES_COMPLETED_TOTAL: "helper/completedCourses/:id",
  STUDENT_COURSES_COMPLETED_SINGLE: "helper/student-course-details",
  STUDENT_AVERAGE_SCORE: "helper/averageScore/:id",
  LECTURES_GRAPH: "admin/reports/lectures-report/:id",
  COURSE_COMPLETED_PERCENT: "admin/reports/completed-coruses-report/:id",
  TEACHER_FEEDBACK: "helper/feedback-by-student/:id",
  REFUND_VS_REVEVUE_REPORT: "admin/reports/revenue-vs-refund",
  ACTIVE_OR_INACTIVE_STUDENT: "admin/reports/get-active-users",
  TOP_STUDENTS_LIST: "helper/top-students",
  TOP_TEACHERS: "helper/top-teachers",
  TOP_COURSES: "helper/top-courses",
  STUDENT_ENROLMENTS: "admin/reports/enrolment-report",
  STUDENT_ENGAEMENT: "admin/reports/student-engagement-report",
  GERNEL_ENROLMENTS: "admin/reports/general-reports",
  ADMIN_STUDENT_REPORTS: "admin/reports/generate-student-report/:id",
  ADMIN_STUDENT_PROGRESS: "helper/averageScore/:id",
  SEARCH_COUSES: "admin/courses/search",
  SEARCH_STUDENT: "admin/users/students",
  TIME_SPENT_STUDENT: "admin/reports/student-availablity-report",
  COUSES_VIEW_ASSIGNMENT: "admin/courses/assignment-content/:id",

  CHANGE_PASSWORD: "admin/auth/update-password",

  COUNTRIES: "helper/get-countries",
  STATES: "helper/get-states/:id",
  CITIES: "helper/get-cities/:id",

  USER_MODULE_PARENT_VIEW: "parent-profile/:id",
  USER_MODULE_PARENT_SAVE: "parent/auth/registration",
  USER_MODULE_PARENT_EDIT: "parent-profile/save/:id",

  USER_MODULE_TEACHER_VIEW: "teacher-profile/:id",
  USER_MODULE_TEACHER_SAVE: "teacher/auth/registration",
  USER_MODULE_TEACHER_EDIT: "teacher-profile/save/:id",

  USER_MODULE_STUDENT_VIEW: "student-profile/:id",
  USER_MODULE_STUDENT_SAVE: "student/auth/registration",
  USER_MODULE_STUDENT_EDIT: "student-profile/save/:id",

  FETCH_ALL_PARENT: "helper/get-parents",
  FETCH_ENROLL_STUDENTS_COURSES: "admin/courses/student-courses/:id",
  PARENT_ENROLLED_STUDENTS: "helper/get-enrolled-students-by-parent/:id",
  USER_TEACHER_STUDENTS: "helper/teacher-students/:id",
  PARENT_STUDENTS_COMPLAINTS: "admin/complaint/complaints-by-user",
  USER_TEACHER_COURSES: "admin/teacher/courses/:id",
  USER_TEACHER_QUIZZES: "admin/teacher/quizzes/:id",
  USER_TEACHER_ASSIGNMENT: "admin/teacher/assignments/:id",
  FETCH_QUIZZES_COURSES: "admin/courses/quizzes/:id",
  FETCH_ASSIGNMENT_COURSES: "admin/courses/assignments/:id",

  GET_COURSES: "helper/get-courses",
  STUDENT_ASSIGNMENT_SHEDULE: "admin/student-assignment/shedule-assignment",
  ADMIN_GENERAL_SETTINGS: "admin/settings/save",
  PARENT_DASHBOARD_STATE: "admin/dashboard/parent-dashboard",
  STUDENT_DASHBOARD_STATE: "admin/dashboard/student-dashboard",
  PARENT_TRANSACTIONS: "admin/transactions/user-transactions",
  PARENT_LOGIN_COUNT: "helper/parent-login-counts",
  ALL_STUDENT_REPORTS: "admin/reports/generate-students-report",
  PARENT_INFO_UPDATE_CHILD: "parent-profile/student/:id",
  GET_QUIZ_RESULT: "admin/student-quiz/quiz-result-answers/:id",
  Edited_PDF_UPLOAD: "admin/student-assignment/submit-checked-assignment",
  QUIZ_DETAIL: "admin/student-quiz/quiz-details/:id",
  TEACHERS_ASSIGNMENT_FEEDBACK: "helper/get-student-assignment-feedback/:id",

  // CMS
  OUR_PROGRAMS_INFO_SAVE: "admin/home-page/programs/info/save",
  OUR_PROGRAMS_INFO_VIEW: "admin/home-page/programs/info/view",
  OUR_PROGRAMS_TABLE_VIEW: "admin/home-page/programs/view/:id",
  OUR_PROGRAMS_TABLE_PAGINATION: "admin/home-page/programs",
  OUR_PROGRAMS_TABLE_EDIT: "admin/home-page/programs/save",
  OUR_PROGRAMS_TABLE_DEL: "admin/home-page/programs/delete/:id",

  HOME_BANNER_INFO_SAVE: "admin/home-page/home-banner/save",
  HOME_BANNER_INFO_VIEW: "admin/home-page/home-banner",

  HOME_SCHOOLING_INFO_SAVE: "admin/home-page/schooling/info/save",
  HOME_SCHOOLING_INFO_VIEW: "admin/home-page/schooling/info/view",
  HOME_SCHOOLING_TABLE_VIEW: "admin/home-page/schooling/view/:id",
  HOME_SCHOOLING_TABLE_PAGINATION: "admin/home-page/schooling",
  HOME_SCHOOLING_TABLE_EDIT: "admin/home-page/schooling/save",
  HOME_SCHOOLING_TABLE_DEL: "admin/home-page/schooling/delete/:id",

  WHY_STUDY_ONLINE_INFO_SAVE: "admin/home-page/study-online/info/save",
  WHY_STUDY_ONLINE_INFO_VIEW: "admin/home-page/study-online/info/view",
  WHY_STUDY_ONLINE_TABLE_VIEW: "admin/home-page/study-online/view/:id",
  WHY_STUDY_ONLINE_TABLE_PAGINATION: "admin/home-page/study-online",
  WHY_STUDY_ONLINE_TABLE_EDIT: "admin/home-page/study-online/save",
  WHY_STUDY_ONLINE_TABLE_DEL: "admin/home-page/study-online/delete/:id",

  SAMPLE_SUBJECT_TABLE_VIEW: "admin/home-page/sample-course/view/:id",
  SAMPLE_SUBJECT_TABLE_PAGINATION: "admin/home-page/sample-course",
  
  SAMPLE_SUBJECT_INFO_SAVE: "admin/home-page/sample-course/info/save",
  SAMPLE_SUBJECT_INFO_VIEW: "admin/home-page/sample-course/info/view",
  SAMPLE_SUBJECT_TABLE_EDIT: "admin/home-page/sample-course/save/:id?",
  SAMPLE_SUBJECT_TABLE_DEL: "admin/home-page/sample-course/delete/:id",

  ENROLL_NOW_INFO_SAVE: "admin/home-page/home-enroll/save",
  ENROLL_NOW_INFO_VIEW: "admin/home-page/home-enroll",

  FRONTEND_FOOTER_SAVE: "admin/home-page/footer/save",
  FRONTEND_FOOTER_VIEW: "admin/home-page/footer/view",

  FRONTEND_CMS_EDITOR_SAVE: "admin/popup/save",
  FRONTEND_CMS_EDITOR_VIEW: "admin/popup/view/:type",
  REGISTRATION_EMAIL_SAVE: "helper/save-customized-email",
  REGISTRATION_EMAIL_VIEW: "helper/get-customized-email/:type",

  NOTICEBOARD_SAVE: "admin/notice/save/:id?",
  NOTICE_BOARD_PAGINATION: "admin/notice",
  NOTICE_BOARD_EDIT: "admin/notice/view/:id",
  NOTICE_BOARD_VIEW: "admin/notice/notice-by-role",
  NOTICE_BOARD_DEL: "admin/notice/delete/:id",
  NOTICE_BOARD_IS_READED: "admin/notice/view-notice",

  // About Us

  ABOUT_BANNER_INFO_SAVE: "admin/about-page/about-banner/save",
  ABOUT_BANNER_INFO_VIEW: "admin/about-page/about-banner",

  // Contact Us

  CONTACT_BANNER_INFO_SAVE: "admin/home-page/contactBanner/save",
  CONTACT_BANNER_INFO_VIEW: "admin/home-page/contact-banner/",
  
  // About Services
  ABOUT_SERVICES_INFO_SAVE:"admin/about-page/organizational-excellence/info/save",
  ABOUT_SERVICES_INFO_VIEW: "admin/about-page/organizational-excellence/info/view",
  ABOUT_SERVICES_TABLE_PAGINATION: "admin/about-page/organizational-excellence/content",
  ABOUT_SERVICES_TABLE_VIEW: "admin/about-page/organizational-excellence/content/view/:id",
  ABOUT_SERVICES_TABLE_EDIT: "admin/about-page/organizational-excellence/content/save",
  ABOUT_SERVICES_TABLE_DEL: "admin/about-page/organizational-excellence/content/delete/:id",

  // FAQS Us

  FAQS_BANNER_INFO_SAVE: "admin/faq-page/banner/save",
  FAQS_BANNER_INFO_VIEW: "admin/faq-page/banner/view",
  
  FAQS_CAT_PAGINATION: "admin/faq-page/categories",
  FAQS_CAT_SAVE: "admin/faq-page/category/save",
  FAQS_CAT_DEL: "admin/faq-page/category/delete/:id",
  FAQS_CAT_VIEW: "admin/faq-page/category/view/:id",
  FAQS_CAT_LIST: "admin/faq-page/categories-list",

  FAQS_PAGINATION: "admin/faq-page/contents",
  FAQS_SAVE: "admin/faq-page/content/save",
  FAQS_DEL: "admin/faq-page/content/delete/:id",
  FAQS_VIEW: "admin/faq-page/content/view/:id",

  // The Super Curriculum

  THE_SUPER_CURRICULUM_BANNER_INFO_SAVE: "admin/curriculum-page/banner/save",
  THE_SUPER_CURRICULUM_BANNER_INFO_VIEW: "admin/curriculum-page/banner/view",

  // IGCSE
  IGCSE_BANNER_INFO_SAVE: "admin/courses-page/save-banner",
  IGCSE_BANNER_INFO_VIEW: "admin/courses-page/view-banner/:type",
  IGCSE_CONTENT_INFO_SAVE: "admin/courses-page/save-content",
  IGCSE_CONTENT_INFO_VIEW: "admin/courses-page/view-content/:type",

  COMPETENCE_INFO_SAVE: "admin/curriculum-page/competence/info/save",
  COMPETENCE_INFO_VIEW: "admin/curriculum-page/competence/info/view",
  COMPETENCE_TABLE_VIEW: "admin/curriculum-page/competence/content/view/:id",
  COMPETENCE_TABLE_PAGINATION: "admin/curriculum-page/competence/content",
  COMPETENCE_TABLE_EDIT: "admin/curriculum-page/competence/content/save",
  COMPETENCE_TABLE_DEL: "admin/curriculum-page/competence/content/delete/:id",

  // MetaTag
  META_TAGS:"admin/meta-tags/:type",
  META_TAGS_SAVE:"admin/meta-tags/save",

  TIME_TRACKING_STUDENT:"helper/tracking-meta",
  TIME_TRACKING_STUDENT_REPORT:"admin/reports/student-activity-report",
  
  SUBJECT_STATUS:"admin/courses/course-toggling/:id",
  SUBJECT_QUIZ_DETAIL_VIEW:"helper/quiz-flow-byId/:id",
  
  GRANT_AND_RETRACT_SUBJECT_ACCESS:"admin/course-access-transition/enrolled-students",
  REVOKW_GRANT_SUBJECT:"admin/course-access-transition/revoke-course",
  GRANT_SUBJECT:"admin/course-access-transition/grant-course",
  SUBJECT_LIST:"helper/get-courses",
  STUDENT_LIST:"helper/parent-student-filter",
  STUDENT_COURSE_LIST:"admin/student/student-courses/:id",


  // STUDENT REPORT DOWNLOAD

  TEACHER_FEEDBACK_PDF: "admin/student/student-quiz-feedbacks/:id",
  TEACHER_ASSIGNMENT_FEEDBACK_PDF: "admin/student/student-assignment-feedbacks/:id",
  STUDENT_COURSE_LIST_PDF: "admin/student/student-courses-report/:id",
  STUDENT_ASSIGN_ASSIGNMENTS_PDF: "admin/student/student-assignments-report/:id",
  STUDENT_ASSIGN_QUIZZES_PDF: "admin/student/student-quizzes-report/:id",


};
