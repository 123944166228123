import React from "react";

// Lazy load all pages to optimize the initial bundle size
const HomePage = React.lazy(() => import("../pages/frontend/home-page"));
const AboutPage = React.lazy(() => import("../pages/frontend/about-page"));
const CurriculumPage = React.lazy(() => import("../pages/frontend/curriculum-page"));
const NotFound = React.lazy(() => import("../pages/frontend/error/NotFound"));
const FaqPage = React.lazy(() => import("../pages/frontend/faq-page"));
const CoursesPage = React.lazy(() => import("../pages/frontend/igcse-page"));
const PrivacyPolicy = React.lazy(() => import("../pages/frontend/privacy-policy"));
const TermsConditions = React.lazy(() => import("../pages/frontend/terms-conditions"));
const ContactPage = React.lazy(() => import("../pages/frontend/contact-page"));

export const FrontendRoutes = {
  path: "/",
  children: [
    {
      path: "",
      element: <HomePage />,
    },
    {
      path: "about",
      element: <AboutPage />,
    },
    {
      path: "contact-us",
      element: <ContactPage />,
    },
    {
      path: "curriculum",
      element: <CurriculumPage />,
    },
    {
      path: "faq",
      element: <FaqPage />,
    },
    {
      path: "igcse",
      element: <CoursesPage type="IGCSE" />,
    },
    {
      path: "secondary-KS3",
      element: <CoursesPage type="Secondary-KS3" />,
    },
    {
      path: "privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "terms-conditions",
      element: <TermsConditions />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ],
};
